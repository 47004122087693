import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as productSlice from "../features/products/product.slice";
import * as authSlice from "../features/auth/auth.slice";
import { UserRoles } from "../shared/enums";

const Dashboard = () => {
    const dispatch = useAppDispatch();

    const products = useAppSelector(productSlice.selectProducts);
    const permissions = useAppSelector(authSlice.selectPermissions);

    const getProducts = () => {
        const fetchProducts = async () => {
            await dispatch(productSlice.getAll());
        }

        fetchProducts();
    }

    return (
        <div>
            <p>
                This is dashboard
            </p>
            {permissions.includes(UserRoles.Admin) &&
                <p>I am Admin</p>
            }
            {permissions.includes(UserRoles.User) &&
                <p>I am User</p>
            }
            <button onClick={getProducts}>
                Get Products
            </button>

            {products &&
                <>
                    <div>Products</div>
                    <div>{products.length}</div>
                    {products.map((product) => {
                        return <p key={product}>{product}</p>
                    })}
                </>
            }
        </div>
    )
}

export default Dashboard;