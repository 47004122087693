import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { configs } from '../app/app-configs';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
// const navItems = ['Home', 'About', 'Contact', 'Login'];

export default function Nav(props: Props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {configs.APP_NAME}
      </Typography>
      <Divider />
      <List>
        {/* <Button><NavLink to='/' style={{ margin: '1rem' }}>Home</NavLink></Button>
        <br />
        <Button><NavLink to='/secured' style={{ margin: '1rem' }}>Secured Page</NavLink></Button>
        <br /> */}
        <Button><NavLink to='/login' style={{ margin: '1rem' }}>Login</NavLink></Button>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            {configs.APP_NAME}
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {/* <Button sx={{ color: '#fff' }}><NavLink to='/' style={{ margin: '1rem', color: '#fff' }}>Home</NavLink></Button>
            <Button sx={{ color: '#fff' }}><NavLink to='/secured' style={{ margin: '1rem', color: '#fff' }}>Secured Page</NavLink></Button> */}
            {/* <Button sx={{ color: '#fff' }}><NavLink to='/login' style={{ margin: '1rem', color: '#fff' }}>Login</NavLink></Button> */}
            <Button sx={{ color: '#fff'}} onClick={() => navigate('/')}>Home</Button>
            <Button sx={{ color: '#fff'}} onClick={() => navigate('/login')}>Login</Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ width: '100%' }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}