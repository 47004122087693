import MembersIcon from '@mui/icons-material/People';
import useMemberSummary from "../../../hooks/use-member-summary";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import StatCard from "../../../components/StatCard";
import { StatSummary } from "../../../components/StatSummary";
import ChartCard from '../../../components/chart/chart-card';
import { Grid } from '@mui/material';
import DoughnutChart from '../../../components/chart/doughnut-chart';

const MemberSummary = () => {
    const memberSummary = useMemberSummary();

    return (
        <div>
            {memberSummary &&
                <>
                    <StatSummary>
                        <StatCard title="Total Count" value={memberSummary?.totalCount.toString()} icon={MembersIcon} />
                        <StatCard title="Joined This Month" value={memberSummary?.currentMonthCount.toString()} icon={MembersIcon} />
                        {/* <StatCard title="Expired" value={memberSummary?.expiredCount.toString()} icon={HourglassTopIcon} /> */}
                    </StatSummary>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={memberSummary.countByGender.map(item => { return { name: item.key, value: item.value } })} label='Count by Gender' /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={memberSummary.countByStatus.map(item => { return { name: item.key, value: item.value } })} label='Count by Status' /></ChartCard>
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ChartCard title='test'><LinesChart /></ChartCard>
                        </Grid> */}
                    </Grid>
                </>
            }
        </div>
    )
}

export default MemberSummary;