import { Box, Chip, IconButton, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { Member } from "../../../service/model/member.model";
import MoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AttendanceIcon from '@mui/icons-material/DirectionsWalk';
import { ReactNode, useState } from "react";
import MemberForm from "./member-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import theme from "../../../app/app-theme";
import { useAppDispatch } from "../../../app/hooks";
import { deleteMember, updateMember } from "../member.slice";
import useMemberships from "../../../hooks/use-memberships";
import AttendanceForm from "../../attendance/components/attendance-form";
import { Attendance } from "../../../service/model/attendance.model";
import { createAttendance } from "../../attendance/attendance.slice";
import { MemberPayment } from "../../../service/model/member-payment.model";
import { createMemberPayment } from "../../member-payments/member-payment.slice";
import MemberPaymentForm from "../../member-payments/components/member-payment-form";

interface TableRowProps {
    readonly member: Member
}

type MemberStatus = 'Active' | 'Inactive' | 'Expired';

const MemberTableRow: React.FC<TableRowProps> = ({ member }) => {
    const dispatch = useAppDispatch();
    const [memberships] = useMemberships();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showMemberForm, setShowMemberForm] = useState(false);
    const [showAttendanceForm, setShowAttendanceForm] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    const handleDelete = () => {
        dispatch(deleteMember(member.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: Member) => {
        dispatch(updateMember({ ...data, id: member.id })).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowMemberForm(false);
            }
        });
    };

    const handleAttendanceSubmit = (data: Attendance) => {
        dispatch(createAttendance(data)).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowAttendanceForm(false);
            }
        });
    };

    const handlePaymentSubmit = (data: MemberPayment) => {
        dispatch(createMemberPayment(data)).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowPaymentForm(false);
            }
        });
    };

    const getStatusChip = (status: MemberStatus): ReactNode => {
        switch (status) {
            case "Active": {
                return <Chip label={status} color="success" />
            }
            case "Inactive": {
                return <Chip label={status} color="warning" />
            }
            case "Expired": {
                return <Chip label={status} color="error" />
            }
            default: {
                return <Chip label={status} color="default" />
            }
        }
    }

    return (
        <>
            <StyledTableRow key={member.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Name</Box>{member.firstName} {member.lastName}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Contact</Box>{member.contact}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Gender</Box>{member.gender}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Due</Box>{member.dueAmount}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Membership</Box>{memberships.find(membership => membership.id === member.membershipId)?.name}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry Date</Box>{member.expiryDate}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Status</Box>{getStatusChip(member.status as MemberStatus)}</TableCell>
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowMemberForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <IconButton onClick={() => setShowPaymentForm(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><MoneyIcon /></IconButton>
                    <IconButton onClick={() => setShowAttendanceForm(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><AttendanceIcon /></IconButton>
                    <IconButton onClick={() => setShowMemberForm(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><EditIcon /></IconButton>
                    <IconButton onClick={() => setShowDeleteDialog(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><DeleteIcon /></IconButton>
                </TableCell>
            </StyledTableRow>
            {showPaymentForm &&
                <MemberPaymentForm payment={{memberId: member.id, firstName: member.firstName, lastName: member.lastName} as MemberPayment} editable={true} onClose={() => setShowPaymentForm(false)} onSubmit={handlePaymentSubmit} />
            }
            {showAttendanceForm &&
                <AttendanceForm attendance={{memberId: member.id, firstName: member.firstName, lastName: member.lastName} as Attendance} editable={true} onClose={() => setShowAttendanceForm(false)} onSubmit={handleAttendanceSubmit} />
            }
            {showMemberForm &&
                <MemberForm member={member} editable={true} onClose={() => setShowMemberForm(false)} onSubmit={handleSubmit} />
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message={`Are you sure you want to delete ${member.firstName} ?`} onConfirm={handleDelete} onCancel={handleDeleteCancel} />
            }
        </>
    );
}

export default MemberTableRow;