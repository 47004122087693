import { useState } from "react";
import StyledTable, { ColumnHeader } from "../../../components/table/styled-table";
import MemberTableRow from "./member-table-row";
import { Member } from "../../../service/model/member.model";
import { configs } from "../../../app/app-configs";

const headers: ColumnHeader[] = [
    { name: "Name" },
    { name: "Contact" },
    { name: "Gender" },
    { name: "Due" },
    { name: "Membership" },
    { name: "Expiry Date" },
    { name: "Status" },
    { name: "Actions", className: "action-header" }
]

interface TableProps {
    readonly members: Member[]
}

const MemberTable: React.FC<TableProps> = ({members}) => {
    
    const [page, setPage] = useState(1);

    const pageCount = Math.ceil(members.length / configs.PAGE_SIZE);
    
    const handlePageChange = (selectedPage: number) => {
        setPage(selectedPage);
    }

    return (
        <StyledTable headers={headers}
            currentPage={page}
            totalPages={pageCount}
            onPageChange={handlePageChange}>

            {members?.map(member =>
                <MemberTableRow member={member} key={member.id} />
            )}
        </StyledTable>
    );
}

export default MemberTable;