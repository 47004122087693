import { Grid, Theme } from "@mui/material";
import aboutBackground from "../resources/images/hero.jpg";
import { makeStyles, createStyles } from "@mui/styles";
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { setIsAuthenticated } from "../features/auth/auth.slice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: `100vh`,
      backgroundImage: `url(${aboutBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "#000",
      backgroundPosition: "center",
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: "right",
      }
    },
    textHeading: {
      color: `cornsilk`,
      fontSize: '64px',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    textDesc: {
      color: `${theme.palette.secondary.light}`,
      fontSize: '24px',
      lineHeight: '1.5',
      textAlign: 'center'
    },
  }
  ));

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
  const classes = useStyles();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(setIsAuthenticated(true));
      navigate('/dashboard');
    } else {
      dispatch(setIsAuthenticated(false));
    }
  })

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.root}>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <div className="text-center">
          <h1 className={classes.textHeading}>Manage Your Business</h1>
          <h4 className={classes.textDesc}>Business management software for increased organization and efficiency</h4>
        </div>
        <div className="text-center" style={{ marginTop: '2rem' }}>
        </div>
      </Grid>
    </Grid>
  )


};

export default Home;