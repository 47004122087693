import axios from "axios";
import { configs } from "../app/app-configs";
import { Attendance, AttendanceSummary } from "./model/attendance.model";

export const getAttendances = async (): Promise<Attendance[]> => {
    return await axios.get(`${configs.BASE_URL}/attendance`)
    .then(res => {
        return res.data as Attendance[];
    });
}

export const createAttendance = async (request: Attendance): Promise<Attendance> => {
    return await axios.post(`${configs.BASE_URL}/attendance`, request)
    .then(res => {
        return res.data as Attendance;
    });
}

export const updateAttendance = async (request: Attendance): Promise<Attendance> => {
    return await axios.put(`${configs.BASE_URL}/attendance/${request.id}`, request)
    .then(res => {
        return res.data as Attendance;
    });
}

export const deleteAttendance = async (id: number): Promise<number> => {
    return await axios.delete(`${configs.BASE_URL}/attendance/${id}`)
    .then(res => {
        return res.data;
    });
}

export const getAttendanceSummary = async (): Promise<AttendanceSummary> => {
    return await axios.get(`${configs.BASE_URL}/attendance/summary`)
    .then(res => {
        return res.data as AttendanceSummary;
    });
}
