import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as membershipService from '../../service/membership.service'
import { Membership } from "../../service/model/membership.model";
import { showNotification } from "../alerts/alert.slice";
import { NotificationType } from "../../shared/enums";
import { getAxiosErrorMessage } from "../../service/axios/axios.call.service";

export interface MembershipState {
    isLoading: boolean;
    memberships: Membership[];
}

const membershipsAdapter = createEntityAdapter({
    selectId: (membership: Membership) => membership.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
  })
  
export const initialState = membershipsAdapter.getInitialState({
    isLoading: false,
    memberships: []
} as MembershipState);

export const fetchMemberships = createAsyncThunk<Membership[]>(
    'memberships/all',
    async (_, {rejectWithValue}) => {
        return membershipService.getMemberships().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const createMembership = createAsyncThunk<Membership, Membership>(
    'memberships/create',
    async (request, {rejectWithValue, dispatch}) => {
        return membershipService.createMembership(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Membership created successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const updateMembership = createAsyncThunk<Membership, Membership>(
    'memberships/update',
    async (request, {rejectWithValue, dispatch}) => {
        return membershipService.updateMembership(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Membership updated successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const deleteMembership = createAsyncThunk<number, number>(
    'memberships/delete',
    async (request, {rejectWithValue, dispatch}) => {
        return membershipService.deleteMembership(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Membership deleted successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

const membershipSlice = createSlice({
    name: 'memberships',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchMemberships.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchMemberships.fulfilled, (state, action: PayloadAction<Membership[]>) => {
            membershipsAdapter.setAll(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchMemberships.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(createMembership.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createMembership.fulfilled, (state, action: PayloadAction<Membership>) => {
            membershipsAdapter.addOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(createMembership.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(updateMembership.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateMembership.fulfilled, (state, action: PayloadAction<Membership>) => {
            membershipsAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(updateMembership.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(deleteMembership.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteMembership.fulfilled, (state, action: PayloadAction<number>) => {
            membershipsAdapter.removeOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(deleteMembership.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectMemberships,
    selectById: selectMembershipById
} = membershipsAdapter.getSelectors<RootState>((state) => state.memberships);

export const {} = membershipSlice.actions;

export const selectIsLoading = (state: RootState) => state.memberships.isLoading;

export default membershipSlice.reducer;