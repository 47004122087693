import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as attendanceService from '../../service/attendance.service'
import { Attendance, AttendanceSummary } from "../../service/model/attendance.model";
import { showNotification } from "../alerts/alert.slice";
import { NotificationType } from "../../shared/enums";
import { getAxiosErrorMessage } from "../../service/axios/axios.call.service";

export interface AttendanceState {
    isLoading: boolean;
    attendances: Attendance[];
    summary?: AttendanceSummary;
}

const attendanceAdapter = createEntityAdapter({
    selectId: (attendance: Attendance) => attendance.id,
    sortComparer: (a, b) => b.date.localeCompare(a.date),
  })
  
export const initialState = attendanceAdapter.getInitialState({
    isLoading: false,
    attendances: [],
} as AttendanceState);

export const fetchAttendanceSummary = createAsyncThunk<AttendanceSummary>(
    'attendances/summary',
    async (_, {rejectWithValue}) => {
        return attendanceService.getAttendanceSummary().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const fetchAttendances = createAsyncThunk<Attendance[]>(
    'attendances/all',
    async (_, {rejectWithValue}) => {
        return attendanceService.getAttendances().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const createAttendance = createAsyncThunk<Attendance, Attendance>(
    'attendances/create',
    async (request, {rejectWithValue, dispatch}) => {
        return attendanceService.createAttendance(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Attendance created successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchAttendances())
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const updateAttendance = createAsyncThunk<Attendance, Attendance>(
    'attendances/update',
    async (request, {rejectWithValue, dispatch}) => {
        return attendanceService.updateAttendance(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Attendance updated successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchAttendanceSummary());
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const deleteAttendance = createAsyncThunk<number, number>(
    'attendances/delete',
    async (request, {rejectWithValue, dispatch}) => {
        return attendanceService.deleteAttendance(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Attendance deleted successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchAttendanceSummary());
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

const attendanceSlice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAttendanceSummary.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchAttendanceSummary.fulfilled, (state, action: PayloadAction<AttendanceSummary>) => {
            state.summary = action.payload;
            state.isLoading = false;
        })
        .addCase(fetchAttendanceSummary.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(fetchAttendances.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchAttendances.fulfilled, (state, action: PayloadAction<Attendance[]>) => {
            attendanceAdapter.setAll(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchAttendances.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(createAttendance.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createAttendance.fulfilled, (state, action: PayloadAction<Attendance>) => {
            attendanceAdapter.addOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(createAttendance.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(updateAttendance.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateAttendance.fulfilled, (state, action: PayloadAction<Attendance>) => {
            attendanceAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(updateAttendance.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(deleteAttendance.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteAttendance.fulfilled, (state, action: PayloadAction<number>) => {
            attendanceAdapter.removeOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(deleteAttendance.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectAttendances,
    selectById: selectAttendanceById
} = attendanceAdapter.getSelectors<RootState>((state) => state.attendance);

export const {} = attendanceSlice.actions;

export const selectIsLoading = (state: RootState) => state.attendance.isLoading;
export const selectAttendanceSummary = (state: RootState) => state.attendance.summary;

export default attendanceSlice.reducer;