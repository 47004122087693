import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAttendances, fetchAttendances } from "../features/attendance/attendance.slice";

const useBusinesess = () => {
    const dispatch = useAppDispatch();
    const attendances = useAppSelector(selectAttendances);

    const getBusinesess = async () => {
        await dispatch(fetchAttendances());
    }

    useEffect(() => {
        if (attendances.length === 0) {
            getBusinesess();
        }
    }, []);

    return [attendances];
}
 
export default useBusinesess;