import { Box, IconButton, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { MemberPayment } from "../../../service/model/member-payment.model";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import MemberPaymentForm from "./member-payment-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import theme from "../../../app/app-theme";
import { useAppDispatch } from "../../../app/hooks";
import { deleteMemberPayment, updateMemberPayment } from "../member-payment.slice";

interface TableRowProps {
    readonly payment: MemberPayment
}

const MemberPaymentTableRow: React.FC<TableRowProps> = ({ payment }) => {
    const dispatch = useAppDispatch();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showMemberPaymentForm, setShowMemberPaymentForm] = useState(false);

    const handleDelete = () => {
        dispatch(deleteMemberPayment(payment.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: MemberPayment) => {
        dispatch(updateMemberPayment({ ...data, id: payment.id, memberId: payment.memberId })).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowMemberPaymentForm(false);
            }
        });
    };

    return (
        <>
            <StyledTableRow key={payment.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Member</Box>{`${payment.firstName} ${payment.lastName}`}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Amount</Box>{payment.amount}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Payment Date</Box>{payment.paymentDate}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowMemberPaymentForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <IconButton onClick={() => setShowMemberPaymentForm(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><EditIcon /></IconButton>
                    <IconButton onClick={() => setShowDeleteDialog(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><DeleteIcon /></IconButton>
                    {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                </TableCell>
            </StyledTableRow>
            {showMemberPaymentForm &&
                <MemberPaymentForm payment={payment} editable={true} onClose={() => setShowMemberPaymentForm(false)} onSubmit={handleSubmit} />
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message="Are you sure you want to delete?" onConfirm={handleDelete} onCancel={handleDeleteCancel} />
            }
        </>
    );
}

export default MemberPaymentTableRow;