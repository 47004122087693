import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
    AuthRequest,
    AuthResponse,
    UserInfo,
} from '../../service/model/user.model';
import * as authService from '../../service/auth.service';
// import jwtDecode from 'jwt-decode';
import { NotificationType, UserRoles } from '../../shared/enums';
import { showNotification } from '../alerts/alert.slice';
import {
    PasswordChangeRequest,
    PasswordResetLinkRequest,
    PasswordResetRequest,
    StatusResponseWithMessage,
} from '../../service/model/auth.model';

export interface AuthState {
    isAuthenticated: boolean;
    permissions: UserRoles[];
    loggedInUser?: UserInfo;
    passwordResetLinkResponse?: StatusResponseWithMessage;
    passwordResetResponse?: StatusResponseWithMessage;
    passwordChangeResponse?: StatusResponseWithMessage;
}

export const initialState: AuthState = {
    isAuthenticated: false,
    permissions: [],
};

export const login = createAsyncThunk<
    AuthResponse,
    AuthRequest,
    { rejectValue: string }
>('auth/login', async (authRequest, { rejectWithValue, dispatch }) => {
    return authService
        .login(authRequest)
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            if (errors.response?.status === 401) {
                dispatch(
                    showNotification({
                        message: 'Invalid credentials',
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue('Invalid credentials');
            } else {
                dispatch(
                    showNotification({
                        message: 'Login failed. Please try again later.',
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue(errors);
            }
        });
});

export const getLoggedInUser = createAsyncThunk<
    UserInfo,
    void,
    { rejectValue: string }
>('auth/logged-in-user', async (_, { rejectWithValue }) => {
    return authService.getAuthUser().catch((errors) => {
        return rejectWithValue(errors);
    });
});

export const sendPasswordResetEmail = createAsyncThunk<
    StatusResponseWithMessage,
    PasswordResetLinkRequest,
    { rejectValue: string }
>('auth/forgot-password', async (email, { rejectWithValue, dispatch }) => {
    return authService
        .sendPasswordResetLink(email)
        .then((response) => {
            if (response.status === 'success') {
                dispatch(
                    showNotification({
                        message:
                            'You will receive a password reset link to the provided email.',
                        type: NotificationType.Success,
                    })
                );
                return response;
            } else {
                dispatch(
                    showNotification({
                        message: response.response.data.message,
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue('Failed to send password reset email');
            }
        })
        .catch((errors) => {
            return rejectWithValue(errors);
        });
});

export const resetPassword = createAsyncThunk<
    StatusResponseWithMessage,
    PasswordResetRequest,
    { rejectValue: string }
>('auth/reset-password', async (request, { rejectWithValue, dispatch }) => {
    return authService
        .resetPassword(request)
        .then((response) => {
            if (response.status === 'success') {
                dispatch(
                    showNotification({
                        message: 'Password changed successfully',
                        type: NotificationType.Success,
                    })
                );
                return response;
            } else if (response.status === 'failure') {
                dispatch(
                    showNotification({
                        message: response.message,
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue('Failed to reset password');
            } else {
                dispatch(
                    showNotification({
                        message: 'Failed to reset password',
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue('Failed to reset password');
            }
        })
        .catch((errors) => {
            return rejectWithValue(errors);
        });
});

export const changePassword = createAsyncThunk<
    StatusResponseWithMessage,
    PasswordChangeRequest,
    { rejectValue: string }
>('auth/change-password', async (request, { rejectWithValue, dispatch }) => {
    return authService
        .changePassword(request)
        .then((response) => {
            if (response.status === 'success') {
                dispatch(
                    showNotification({
                        message: 'Password changed successfully',
                        type: NotificationType.Success,
                    })
                );
                return response;
            } else if (response.status === 'failure') {
                dispatch(
                    showNotification({
                        message: response.message,
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue('Failed to reset password');
            } else {
                dispatch(
                    showNotification({
                        message: 'Failed to reset password',
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue('Failed to reset password');
            }
        })
        .catch((errors) => {
            return rejectWithValue(errors);
        });
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        },
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {})
            .addCase(login.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                action.payload.accessToken &&
                    localStorage.setItem(
                        'access_token',
                        action.payload.accessToken
                    );
                action.payload.refreshToken &&
                    localStorage.setItem(
                        'refresh_token',
                        action.payload.refreshToken
                    );
                // const user: any = jwtDecode(action.payload.accessToken);
                // state.permissions = user.roles.map(
                //     (permission: any) => permission.authority
                // );
            })
            .addCase(login.rejected, () => {
                return initialState;
            })
            .addCase(getLoggedInUser.pending, (state) => {
                state.loggedInUser = undefined;
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.loggedInUser = action.payload;
                state.permissions = action.payload.roles.split(",") as UserRoles[];
            })
            .addCase(getLoggedInUser.rejected, (state) => {
                state.loggedInUser = undefined;
            })
            .addCase(sendPasswordResetEmail.pending, (state) => {
                state.passwordResetLinkResponse = undefined;
            })
            .addCase(sendPasswordResetEmail.fulfilled, (state, action) => {
                state.passwordResetLinkResponse = action.payload;
            })
            .addCase(sendPasswordResetEmail.rejected, (state) => {
                state.passwordResetLinkResponse = undefined;
            })
            .addCase(resetPassword.pending, (state) => {
                state.passwordResetResponse = undefined;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.passwordResetResponse = action.payload;
            })
            .addCase(resetPassword.rejected, (state) => {
                state.passwordResetResponse = undefined;
            })
            .addCase(changePassword.pending, (state) => {
                state.passwordChangeResponse = undefined;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.passwordChangeResponse = action.payload;
            })
            .addCase(changePassword.rejected, (state) => {
                state.passwordChangeResponse = undefined;
            });
    },
});

export const selectIsAuthenticated = (state: RootState) =>
    state.auth.isAuthenticated;
export const selectPermissions = (state: RootState) => state.auth.permissions;
export const selectLoggedInUser = (state: RootState) => state.auth.loggedInUser;
export const selectPasswordResetLinkResponse = (state: RootState) =>
    state.auth.passwordResetLinkResponse;
export const selectPasswordResetResponse = (state: RootState) =>
    state.auth.passwordResetResponse;
export const selectPasswordChangeResponse = (state: RootState) =>
    state.auth.passwordChangeResponse;

export const { resetState, setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
