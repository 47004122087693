import { addDays } from 'date-fns';
import { configs } from '../../app/app-configs';
import { Pagination } from '../../model/pagination';

export interface MemberPayment {
    id: number;
    memberId: number;
    membershipId: number;
    firstName: string;
    lastName: string;
    amount: number;
    paymentDate: string;
    fromDate: string;
}

export interface MemberPaymentSummary {
    todayTotal: number;
    currentWeekTotal: number;
    currentMonthTotal: number;
}

export interface MemberPaymentSearchCriteria {
    pagination: Pagination;
    name: string;
    from: string;
    to: string;
}

export const getDefaultMemberPaymentSearchCriteria =
    (): MemberPaymentSearchCriteria => {
        return {
            pagination: { page: 0, size: configs.PAGE_SIZE },
            name: '',
            from: addDays(new Date(), -7).toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
        };
    };
