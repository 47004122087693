import axios from "axios";
import { configs } from "../app/app-configs";
import { Membership } from "./model/membership.model";

export const getMemberships = async (): Promise<Membership[]> => {
    return await axios.get(`${configs.BASE_URL}/membership`)
    .then(res => {
        return res.data as Membership[];
    });
}

export const createMembership = async (request: Membership): Promise<Membership> => {
    return await axios.post(`${configs.BASE_URL}/membership`, request)
    .then(res => {
        return res.data as Membership;
    });
}

export const updateMembership = async (request: Membership): Promise<Membership> => {
    return await axios.put(`${configs.BASE_URL}/membership/${request.id}`, request)
    .then(res => {
        return res.data as Membership;
    });
}

export const deleteMembership = async (id: number): Promise<number> => {
    return await axios.delete(`${configs.BASE_URL}/membership/${id}`)
    .then(res => {
        return res.data;
    });
}