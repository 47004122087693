import { UseFormRegisterReturn, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Member } from "../../../service/model/member.model";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton } from "@mui/material";
import FormField from "../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../app/app-theme";
import { TextInput } from "../../../components/form-input-field-wrappers/text-input";
import { DropdownInput } from "../../../components/form-input-field-wrappers/dropdown-input";
import useMemberships from "../../../hooks/use-memberships";

interface MemberFormProps {
    readonly member?: Member
    readonly editable: boolean
    readonly onClose: () => void
    readonly onSubmit: (data: Member) => void
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '100%',
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const validationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Not a valid email"),
    gender: yup.string().required("Gender is required"),
    dateOfBirth: yup.string(),
    contact: yup.string(),
    address: yup.string(),
    status: yup.string().required("Status is required"),
    dueAmount: yup.number(),
    membershipId: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required("Membership is required"),
    joinedDate: yup.string(),
    expiryDate: yup.string(),
});

export type FormValues = yup.InferType<typeof validationSchema>;

const MemberForm: React.FC<MemberFormProps> = ({ member, editable, onClose, onSubmit }) => {
    const [memberships] = useMemberships();

    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema)
    });

    const onSubmitHandler = (data: any) => {
        const updatedMember = {
            ...member,
            ...data
        }
        onSubmit(updatedMember as Member)
    };

    const getRegister = (name: keyof FormValues, validation?: any): UseFormRegisterReturn => {
        return register(name, validation);
    };

    return (
        <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth='md'>
            <DialogTitle sx={{ paddingBottom: 0 }}>Member Details</DialogTitle>

            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Grid container direction="row" className="mt-0 my-0" justifyContent='space-between'>
                            <FormField>
                                <TextInput
                                    id="firstName"
                                    label="First Name"
                                    defaultValue={member?.firstName}
                                    error={errors.firstName !== undefined}
                                    helperText={errors.firstName?.message}
                                    register={getRegister('firstName')}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="lastName"
                                    label="Last Name"
                                    defaultValue={member?.lastName}
                                    error={errors.lastName !== undefined}
                                    helperText={errors.lastName?.message}
                                    register={getRegister('lastName')}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="email"
                                    label="Email"
                                    defaultValue={member?.email}
                                    error={errors.email !== undefined}
                                    helperText={errors.email?.message}
                                    register={register("email")}
                                />
                            </FormField>
                            <FormField>
                                <DropdownInput
                                    id={"gender"}
                                    label="Gender"
                                    defaultValue={member?.gender}
                                    error={errors.gender !== undefined}
                                    helperText={errors.gender?.message}
                                    options={["Male", "Female"].map(item => { return { label: item, value: item } })}
                                    register={register("gender")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="dateOfBirth"
                                    label="Birth Day"
                                    type="date"
                                    defaultValue={member?.dateOfBirth ?? ''}
                                    error={errors.dateOfBirth !== undefined}
                                    helperText={errors.dateOfBirth?.message}
                                    register={register("dateOfBirth")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="contact"
                                    label="Contact"
                                    defaultValue={member?.contact}
                                    register={register("contact")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="address"
                                    label="Address"
                                    defaultValue={member?.address}
                                    register={register("address")}
                                />
                            </FormField>
                            <FormField>
                                <DropdownInput
                                    id={"status"}
                                    label="Status"
                                    defaultValue={member?.status}
                                    error={errors.status !== undefined}
                                    helperText={errors.status?.message}
                                    options={["Active", "Inactive", "Expired"].map(item => { return { label: item, value: item } })}
                                    register={register("status")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="dueAmount"
                                    label="Due Amount"
                                    type="number"
                                    defaultValue={member?.dueAmount ?? 0}
                                    error={errors.dueAmount !== undefined}
                                    helperText={errors.dueAmount?.message}
                                    register={register("dueAmount")}
                                />
                            </FormField>
                            <FormField>
                                <DropdownInput
                                    id={"membership"}
                                    label="Membership"
                                    name="Membership"
                                    defaultValue={member?.membershipId}
                                    error={errors.membershipId !== undefined}
                                    helperText={errors.membershipId?.message}
                                    options={memberships.map(membership => { return { label: membership.name, value: membership.id } })}
                                    register={register("membershipId")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="joinedDate"
                                    label="Joined Date"
                                    type="date"
                                    defaultValue={member?.joinedDate ?? new Date().toISOString().slice(0, 10)}
                                    error={errors.joinedDate !== undefined}
                                    helperText={errors.joinedDate?.message}
                                    register={register("joinedDate")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="expiryDate"
                                    label="Expiry Date"
                                    type="date"
                                    defaultValue={member?.expiryDate ?? ''}
                                    error={errors.expiryDate !== undefined}
                                    helperText={errors.expiryDate?.message}
                                    register={register("expiryDate")}
                                />
                            </FormField>
                        </Grid>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained">Submit</Button>
                        </Grid>
                    </form>
                </>
            </Box>
        </Dialog>
    );
}

export default MemberForm;