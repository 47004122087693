import { useState } from "react";
import StyledTable, { ColumnHeader } from "../../../components/table/styled-table";
import AttendanceTableRow from "./attendance-table-row";
import { Attendance } from "../../../service/model/attendance.model";
import { configs } from "../../../app/app-configs";

const headers: ColumnHeader[] = [
    { name: "First Name" },
    { name: "Last Name" },
    { name: "Date" },
    { name: "Time" },
    { name: "Actions", className: "action-header" }
]

interface TableProps {
    readonly attendances: Attendance[]
}

const AttendanceTable: React.FC<TableProps> = ({attendances}) => {
    
    const [page, setPage] = useState(1);

    const pageCount = Math.ceil(attendances.length / configs.PAGE_SIZE);
    
    const handlePageChange = (selectedPage: number) => {
        console.log('page changed: ', selectedPage);
        setPage(selectedPage);
    }

    return (
        <StyledTable headers={headers}
            currentPage={page}
            totalPages={pageCount}
            onPageChange={handlePageChange}>

            {attendances?.map(attendance =>
                <AttendanceTableRow attendance={attendance} key={attendance.id} />
            )}
        </StyledTable>
    );
}

export default AttendanceTable;