import axios from 'axios';
import { configs } from '../../app/app-configs';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

// const api = axios.create({
//     baseURL: `${configs.BASE_URL}`, // our API base URL
// });

// Request interceptor for adding the bearer token
export const requestInterceptor = (): void => {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('access_token'); // Assuming you store the token in localStorage
            config.headers.Accept = `Application/json`;
            if (token && !config.url?.endsWith('/login') && !config.url?.endsWith('/refresh-token')) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

export const responseInterceptor = (): void => {
    axios.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = localStorage.getItem('refresh_token');

            const tokenData: any = jwtDecode(refreshToken || '');
            const isExpired = dayjs.unix(tokenData.exp).diff(dayjs()) < 1;

            if(!isExpired) { // only if refresh token not expired
            return await axios.post(`${configs.BASE_URL}/auth/refresh-token`, {}, { headers: { 'Authorization': `Bearer ${refreshToken}` } })
                .then(res => {
                                        localStorage.setItem('access_token', res.data.accessToken);
                    localStorage.setItem('refresh_token', res.data.refreshToken);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.accessToken;
                    originalRequest.headers['Authorization'] = 'Bearer ' + res.data.accessToken;
                    return axios(originalRequest);
                }).catch(error => {
                    console.log('----------refresh error:', error);
                });
            } else {
                localStorage.clear();
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    });
}

// Export the api instance
// export default api;
