import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlansIcon from '@mui/icons-material/CurrencyExchange';
import MembershipIcon from '@mui/icons-material/CardMembership';
import MemberIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import AttendanceIcon from '@mui/icons-material/DirectionsWalk';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet, useNavigate } from 'react-router-dom';
import ProfileMenu from './profile-menu';
import { useAppSelector } from '../app/hooks';
import { selectPermissions } from '../features/auth/auth.slice';
import { UserRoles } from '../shared/enums';
import { configs } from '../app/app-configs';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
}

export default function HomeNav(props: Props) {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const permissions = useAppSelector(selectPermissions);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const handleLogout = () => {
  //   localStorage.clear();
  //   dispatch(setIsAuthenticated(false));
  //   navigate('/');
  // }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem onClick={() => navigate("/dashboard")} sx={{ cursor: 'pointer' }}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        {permissions.includes(UserRoles.Admin) &&
          <>
            {/* <ListItem onClick={() => navigate("/secured")}>
              <ListItemIcon><SecuredIcon /></ListItemIcon>
              <ListItemText primary={"Secured"} />
            </ListItem> */}
            <ListItem onClick={() => navigate("/plans")} sx={{ cursor: 'pointer' }}>
              <ListItemIcon><PlansIcon /></ListItemIcon>
              <ListItemText primary={"Plans"} />
            </ListItem>
            <ListItem onClick={() => navigate("/business")} sx={{ cursor: 'pointer' }}>
              <ListItemIcon><BusinessIcon /></ListItemIcon>
              <ListItemText primary={"Business"} />
            </ListItem>
            <ListItem onClick={() => navigate("/business-payments")} sx={{ cursor: 'pointer' }}>
              <ListItemIcon><MoneyIcon /></ListItemIcon>
              <ListItemText primary={"Received"} />
            </ListItem>
          </>
        }
        {permissions.includes(UserRoles.User) &&
          <>
            <ListItem onClick={() => navigate("/memberships")} sx={{ cursor: 'pointer' }}>
              <ListItemIcon><MembershipIcon /></ListItemIcon>
              <ListItemText primary={"Memberships"} />
            </ListItem>
            <ListItem onClick={() => navigate("/members")} sx={{ cursor: 'pointer' }}>
              <ListItemIcon><MemberIcon /></ListItemIcon>
              <ListItemText primary={"Members"} />
            </ListItem>
            <ListItem onClick={() => navigate("/attendance")} sx={{ cursor: 'pointer' }}>
              <ListItemIcon><AttendanceIcon /></ListItemIcon>
              <ListItemText primary={"Attendance"} />
            </ListItem>
            <ListItem onClick={() => navigate("/member-payments")} sx={{ cursor: 'pointer' }}>
              <ListItemIcon><MoneyIcon /></ListItemIcon>
              <ListItemText primary={"Received"} />
            </ListItem>
          </>
        }
      </List>
      {/* <Divider />
      <List>
        <ListItem onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List> */}
    </div>
  );

  // Remove this const when copying and pasting into your project.
  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {configs.APP_NAME}
          </Typography>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClick={handleDrawerToggle}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, backgroundColor: (theme) => theme.palette.background.default }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
